/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/19e600022e377fbe-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b3aa6f2c353ff296-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1a12da09b8ab9c7a-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/66277f3a1ae6d177-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/820afe9433f5e812-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d82021075ed73a2f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ca34c2eb99af4f7b-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ad4f9d2a55ffe2ea-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b572702c81586497-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bfeb88bae9cff504-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/388c5c7f53a91140-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b2b3553aef1d20ca-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/35ac7951a5e682e3-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d4b255d83218b41c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/075d5cba638fe90e-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/27a6941257f12dc0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/dbcb1b45c03cfa9d-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/8d2c92f600e2655a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4d5cae31d7c7517b-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/b09be548b996fe80-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a55e302a1fadf946-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f5df9bb82b39b1d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c566a68d4ef270f0-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f876dfe2e8ed0b7a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a0699cace6f22665-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1b86bbb0e0aba1f4-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a8cee68ffb1b6564-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/64a52b4e53bd2712-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/cb3824f1d7eb6ced-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f70e04fec95eb4ee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7d82a10ce072b2a1-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/5f23b7e296a29a0b-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ab03b37c15855205-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a7610dba1678ee1d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8158c7bae1960491-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/daacbd8d426571ce-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/186afecab029f299-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/72265cbb84e754a6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0485280d42c00163-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sarabun_af9637';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/54eb3a928fcf623e-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sarabun_Fallback_af9637';src: local("Arial");ascent-override: 109.45%;descent-override: 23.78%;line-gap-override: 0.00%;size-adjust: 97.58%
}.__className_af9637 {font-family: '__Sarabun_af9637', '__Sarabun_Fallback_af9637'
}.__variable_af9637 {--font-sarabun: '__Sarabun_af9637', '__Sarabun_Fallback_af9637'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ca0386';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_ca0386';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_ca0386 {font-family: '__Inter_ca0386', '__Inter_Fallback_ca0386';font-style: normal
}.__variable_ca0386 {--font-inter: '__Inter_ca0386', '__Inter_Fallback_ca0386'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9d6348cbc02f74bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/0749ca4bf38039b8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/e9d6ef3b3feffde1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/e27e0dc75430c4b2-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7bf4e9007838c887-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/77c246ac01e35ead-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/171883e03d2067b6-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/2084b837b601951d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9366901f2e0806d1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/398b4bb64e745c45-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/3a634e28c4bd54fb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/d9f1473a63f76975-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/0a0ce249959137f3-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_Tight_e1acf1';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c4c7b0ec92b72e30-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Tight_Fallback_e1acf1';src: local("Arial");ascent-override: 100.51%;descent-override: 25.03%;line-gap-override: 0.00%;size-adjust: 96.39%
}.__className_e1acf1 {font-family: '__Inter_Tight_e1acf1', '__Inter_Tight_Fallback_e1acf1'
}.__variable_e1acf1 {--font-inter-tight: '__Inter_Tight_e1acf1', '__Inter_Tight_Fallback_e1acf1'
}

